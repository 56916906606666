import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { IconButton } from '../../../../../components/icon-button'
import {
  CheckRadioOffIcon,
  CheckRadioWhiteOnIcon,
} from '../../components/icons'
import { Badge } from '../../components/badge'
import { useSetters, useGetters } from '../../model'
import styles from './order-food-program-duration.module.css'

export const OrderFoodProgramDuration = ({ setIsChanged }) => {
  const { t } = useTranslation()
  const getters = useGetters()
  const setters = useSetters()
  const testovani = localStorage.getItem('website-version')

  const handleOrderProgramDuration2Weeks = () => {
    if (!getters.isOrderProgramDuration2Weeks) {
      setters.setOrderProgramDuration2Weeks()
      setIsChanged(true)
    }

    const program_length = 'TWO_WEEKS'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program_length',
        pageURL: `${window.location.pathname}/program_length=${program_length}`,
        program_length
      })

  };

  const handleOrderProgramDuration1Month = () => {
    if (!getters.isOrderProgramDuration1Month) {
      setters.setOrderProgramDuration1Month()
      setIsChanged(true)
    }

    const program_length = 'ONE_MONTH'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program_length',
        pageURL: `${window.location.pathname}/program_length=${program_length}`,
        program_length
      })
  };

  const handleOrderProgramDuration2Month = () => {
    if (!getters.isOrderProgramDuration2Month) {
      setters.setOrderProgramDuration2Month()
      setIsChanged(true)
    }
    const program_length = 'TWO_MONTH'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program_length',
        pageURL: `${window.location.pathname}/program_length=${program_length}`,
        program_length
      })

  };

  const handleOrderProgramDuration3Month = () => {
    if (!getters.isOrderProgramDuration3Month) {
      setters.setOrderProgramDuration3Month()
      setIsChanged(true)
    }
    const program_length = 'THREE_MONTH'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program_length',
        pageURL: `${window.location.pathname}/program_length=${program_length}`,
        program_length
      })

  };

  const is6Dni = getters.isOrderFoodWeekMeal6DaysLength

  return (
    <div className={styles.programBlock}>
      <h3>{t('home.orderFoodOnline.programLengthTitle')}</h3>
      <span className={styles.subTitleBlock}>
        <p>{t('home.orderFoodOnline.programLengthSubTitle')}</p>
      </span>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonBlock}>
            <IconButton onClick={handleOrderProgramDuration2Weeks} fullWidth>
              <ButtonDuration
                title={t(
                  `home.orderFoodOnline.${
                    is6Dni ? 'program2weeks' : 'program2weeks5'
                  }`
                )}
                active={getters.isOrderProgramDuration2Weeks}
              >
                <span>
                  {getters.getOrderFoodDurationPriceVariantsPerDay.twoWeekPrice}{' '}
                  {t('czhKrona')}{' '}
                  <span className={styles.day}>/{t('day')}</span>
                </span>
              </ButtonDuration>
            </IconButton>
          </div>

          <div className={styles.buttonBlock}>
            <IconButton onClick={handleOrderProgramDuration1Month} fullWidth>
              <ButtonDuration
                title={t(
                  `home.orderFoodOnline.${
                    is6Dni ? 'programMonth' : 'programMonth5'
                  }`
                )}
                active={getters.isOrderProgramDuration1Month}
              >
                <span>
                  {getters.getOrderFoodDurationPriceVariantsPerDay.monthPrice}{' '}
                  {t('czhKrona')}{' '}
                  <span className={styles.day}>/{t('day')}</span>
                </span>
                <span className={styles.discountBlock}>
                  <Badge variant="darkOrange" size="sm">
                    -
                    {Math.abs(
                      getters.getOrderFoodDurationPriceVariantsDiscount
                        .monthPrice
                    ).toFixed()}
                    %
                  </Badge>
                </span>
              </ButtonDuration>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton onClick={handleOrderProgramDuration2Month} fullWidth>
              <ButtonDuration
                title={t(
                  `home.orderFoodOnline.${
                    is6Dni ? 'program2months' : 'program2months5'
                  }`
                )}
                active={getters.isOrderProgramDuration2Month}
              >
                <span>
                  {
                    getters.getOrderFoodDurationPriceVariantsPerDay
                      .twoMonthPrice
                  }{' '}
                  {t('czhKrona')}{' '}
                  <span className={styles.day}>/{t('day')}</span>
                </span>
                <span className={styles.discountBlock}>
                  <Badge variant="darkOrange" size="sm">
                    -
                    {Math.abs(
                      getters.getOrderFoodDurationPriceVariantsDiscount
                        .twoMonthPrice
                    ).toFixed()}
                    %
                  </Badge>
                </span>
              </ButtonDuration>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton onClick={handleOrderProgramDuration3Month} fullWidth>
              <ButtonDuration
                title={t(
                  `home.orderFoodOnline.${
                    is6Dni ? 'program3months' : 'program3months5'
                  }`
                )}
                active={getters.isOrderProgramDuration3Month}
                best
              >
                <span>
                  {
                    getters.getOrderFoodDurationPriceVariantsPerDay
                      .threeMonthPrice
                  }{' '}
                  {t('czhKrona')}{' '}
                  <span className={styles.day}>/{t('day')}</span>
                </span>
                <span className={styles.discountBlock}>
                  <Badge variant="darkOrange" size="sm">
                    -
                    {Math.abs(
                      getters.getOrderFoodDurationPriceVariantsDiscount
                        .threeMonthPrice
                    ).toFixed()}
                    %
                  </Badge>
                </span>
                <span className={styles.badgeBlock}>
                  <Badge variant="yellow" size="sm">
                    {t('home.orderFoodOnline.bestProgram')}
                  </Badge>
                </span>
              </ButtonDuration>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const ButtonDuration = props => {
  const { title, active, best, children } = props

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: active,
        [styles.best]: best,
      })}
    >
      <span className={styles.titleBlock}>
        <CheckRadio active={active} />
        <span className={styles.title}>{title}</span>
      </span>
      <p>{children}</p>
    </div>
  )
}

const CheckRadio = props => {
  const { active } = props
  return active ? <CheckRadioWhiteOnIcon /> : <CheckRadioOffIcon />
}
