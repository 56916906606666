import
  React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../components/dropdown'
import { DropdownButton } from '../../components/dropdown-button'
import { DropdownOption } from '../../components/dropdown-option'
import { Badge } from '../../components/badge'
import { useGetters, useSetters } from '../../model'
import styles from './order-food-number-of-meals.module.css'
import { OrderNumberOfMeals } from '../../model/order-food-online-type'

export const OrderFoodNumberOfMeals = ({ setIsChanged }) => {
  const { t } = useTranslation();
  const isEn = document.location.pathname.includes('/en');
  const getters = useGetters();
  const setters = useSetters();
  const meals = getters.getOrderFoodNumberOfMeals;
  const kcalVariants = getters.getOrderNumberOfKcalVariants;

  const handleKcal = value => {
    setters.setOrderFoodNumberOfKcal(value);
    setIsChanged(true);


    const kcal_count = value
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_kcal_count',
        pageURL: `${window.location.pathname}/kcal_count=${kcal_count}`,
        kcal_count
      })

  };

  const { menu } = useMenuOptionsFactory();


  const handleOrderFoodNumberOfMealsChange = item => {
    if (getters.getOrderFoodNumberOfMeals !== item?.id) {
      setters.setOrderFoodNumberOfMeals(item?.id);
      setIsChanged(true);
    }

    const meal_count = item.value
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_meal_count',
        pageURL: `${window.location.pathname}/meal_count=${meal_count}`,
        meal_count
      })

  };

  return (
    <div>
      <div className={styles.numberOfMealsHeader}>
        <h3>{t('home.orderFoodOnline.numberOfMealsTitle')}</h3>
        <span className={styles.subTitleBlock}>
          <span className={styles.subTitle}>
            {t('home.orderFoodOnline.numberOfMealsSubTitle')}
          </span>{' '}
          {isEn ? (
            <Badge>{meals} meals</Badge>
          ) : (
            <Badge>
              {meals} {meals === 5 ? 'jídel' : 'jídla'}
            </Badge>
          )}
        </span>
      </div>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <div className={styles.dropdownBlock}>
            <p className={styles.title}>
              {t('home.orderFoodOnline.numberOfMeals')}
            </p>
            <div className={styles.dropdown}>
              <Dropdown
                fullWidth
                menu={menu.map(item => (
                  <DropdownOption
                    disabled={item?.disabled}
                    value={item?.value}
                    onClick={() => handleOrderFoodNumberOfMealsChange(item)}
                  >
                    <div>{item?.label}</div>
                  </DropdownOption>
                ))}
              >
                <DropdownButton>
                  <div>{menuFormat(getters.getOrderFoodNumberOfMeals)}</div>
                </DropdownButton>
              </Dropdown>
            </div>
          </div>

          <span className={styles.divider} />

          <div className={styles.dropdownBlock}>
            <p className={styles.title}>
              {t('home.orderFoodOnline.calorieCount')}
            </p>
            <div className={styles.dropdown}>
              <Dropdown
                fullWidth
                menu={kcalVariants.map(x => (
                  <DropdownOption
                    disabled={kcalVariants.length <= 1}
                    onClick={() => handleKcal(x)}
                  >
                    <div>
                      {kcalFormat(x)} kcal{' '}
                      <span>({kcalFormat(getters.calculateKcalToKJ(x))})</span>
                    </div>
                  </DropdownOption>
                ))}
              >
                <DropdownButton>
                  <div>
                    {kcalFormat(getters.getOrderNumberOfKcal)} kcal
                    <span>({kcalFormat(getters.getOrderNumberOfKJ)}) kJ</span>
                  </div>
                </DropdownButton>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const kcalFormat = value => {
  return (value || '')?.toLocaleString().replace(',', ' ')
}

const menuFormat = value => {
  const isEn = document.location.pathname.includes('/en')
  switch (true) {
    case OrderNumberOfMeals.Two === value:
      return isEn ? '2-course menu' : '2chodové menu'
    case OrderNumberOfMeals.Three === value:
      return isEn ? '3-course menu' : '3chodové menu'
    case OrderNumberOfMeals.Four === value:
      return isEn ? '4-course menu' : '4chodové menu'
    case OrderNumberOfMeals.Five === value:
      return isEn ? '5-course menu' : '5chodové menu'
    default:
      return ''
  }
}

const useMenuOptionsFactory = () => {
  const getters = useGetters()
  const { t } = useTranslation()

  let menu = []

  if (getters.isOrderFoodProgramWeightLoss) {
    menu = getOrderFoodProgramWeightLossOptions(t)
  }

  if (getters.isOrderFoodProgramWeightGain) {
    menu = getOrderFoodProgramRecruitmentOptions(t)
  }

  if (getters.isOrderFoodProgramMaintenance) {
    menu = getOrderFoodProgramMaintenanceOptions(t)
  }

  if (getters.isOrderFoodProgramLunchAndDinner) {
    menu = getOrderFoodProgramLunchAndDinnerOptions(t)
  }

  return {
    menu,
  }
}

const getOrderFoodProgramWeightLossOptions = t => {
  return [
    {
      id: 5,
      value: '5chodové menu',
      label: t('forms.mainFormMenuOption1'),
      disabled: false,
    },
    {
      id: 4,
      value: '4chodové menu',
      label: t('forms.mainFormMenuOption4'),
      disabled: false,
    },
    {
      id: 3,
      value: '3chodové menu',
      label: t('forms.mainFormMenuOption2'),
      disabled: false,
    },
    {
      id: 2,
      value: '2chodové menu',
      label: t('forms.mainFormMenuOption3'),
      disabled: true,
      active: true,
    },
  ]
}

const getOrderFoodProgramRecruitmentOptions = t => {
  return [
    {
      id: 5,
      value: '5chodové menu',
      label: t('forms.mainFormMenuOption1'),
      disabled: false,
    },
    {
      id: 4,
      value: '4chodové menu',
      label: t('forms.mainFormMenuOption4'),
      disabled: true,
    },
    {
      id: 3,
      value: '3chodové menu',
      label: t('forms.mainFormMenuOption2'),
      disabled: true,
      active: true,
    },
    {
      id: 2,
      value: '2chodové menu',
      label: t('forms.mainFormMenuOption3'),
      disabled: true,
    },
  ]
}

const getOrderFoodProgramMaintenanceOptions = t => {
  return [
    {
      id: 5,
      value: '5chodové menu',
      label: t('forms.mainFormMenuOption1'),
      disabled: false,
    },
    {
      id: 4,
      value: '4chodové menu',
      label: t('forms.mainFormMenuOption4'),
      disabled: false,
    },
    {
      id: 3,
      value: '3chodové menu',
      label: t('forms.mainFormMenuOption2'),
      disabled: false,
    },
    {
      id: 2,
      value: '2chodové menu',
      label: t('forms.mainFormMenuOption3'),
      disabled: true,
      active: true,
    },
  ]
}

const getOrderFoodProgramLunchAndDinnerOptions = t => {
  return [
    {
      id: 5,
      value: '5chodové menu',
      label: t('forms.mainFormMenuOption1'),
      disabled: true,
      active: true,
    },
    {
      id: 4,
      value: '4chodové menu',
      label: t('forms.mainFormMenuOption4'),
      disabled: true,
    },
    {
      id: 3,
      value: '3chodové menu',
      label: t('forms.mainFormMenuOption2'),
      disabled: true,
    },
    {
      id: 2,
      value: '2chodové menu',
      label: t('forms.mainFormMenuOption3'),
      disabled: false,
    },
  ]
}
