import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { IconButton } from '../../../../../components/icon-button'
import { MaleIcon, FemaleIcon } from '../../components/icons'
import { ButtonGroup } from '../../components/button-group'
import { useSetters, useGetters } from '../../model'
import styles from './order-food-customer-gender.module.css'

export const OrderFoodCustomerGender = ({ setIsChanged }) => {
  const { t } = useTranslation();
  const setters = useSetters();
  const getters = useGetters();



  const handleMaleClick = () => {
    if (!getters.isOrderFoodCustomerGenderMale) {
      setters.setOrderFoodCustomerGenderMale();
      setIsChanged(true);
    }

    const sex_choice = 'Male'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `online_order_sex_choice`,
        pageURL: `${window.location.pathname}/sex_choice=${sex_choice}`,
        sex_choice
      })

  };

  const handleFemaleClick = () => {
    if (!getters.isOrderFoodCustomerGenderFemale) {
      setters.setOrderFoodCustomerGenderFemale();
      setIsChanged(true);
    }
    const sex_choice = 'Female'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `online_order_sex_choice`,
        pageURL: `${window.location.pathname}/sex_choice=${sex_choice}`,
        sex_choice
      })

  };

  return (
    <div>
      <h3>{t('home.orderFoodOnline.customerGenderTitle')}</h3>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <ButtonGroup>
            <div className={styles.buttonBlock}>
              <IconButton fullWidth onClick={handleFemaleClick}>
                <ButtonFemale>
                  {t('home.orderFoodOnline.customerGenderFemale')}
                </ButtonFemale>
              </IconButton>
            </div>
            <div className={styles.buttonBlock}>
              <IconButton fullWidth onClick={handleMaleClick}>
                <ButtonMale>
                  {t('home.orderFoodOnline.customerGenderMale')}
                </ButtonMale>
              </IconButton>
            </div>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

const ButtonMale = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerGenderMale,
      })}
    >
      <MaleIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonFemale = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerGenderFemale,
      })}
    >
      <FemaleIcon />
      <p>{children}</p>
    </div>
  )
}
