import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Container } from '../../../../../components/container'
import styles from './order-food-online-template.module.css'
import { Steps } from '../steps'
import { CloseXIcon, LogoIcon } from '../icons'

export const OrderFoodOnlineTemplate = props => {
  const { t } = useTranslation()
  const { sidebar, price, back, children } = props

  return (
    <section className={styles.section}>
      <div className={cx(styles.backBlock, styles.mobile)}>{back}</div>
      <Steps step={1} />

      <Container className={cx('text-center', styles.container)}>
        <div className={styles.formWrapContainer}>
          <div className={styles.formInnerContainer}>
            <div className={styles.grid}>
              {/* <div className={styles.selectColumn}>
                <div className={styles.isFlex}>
                  <div className={styles.childrenBlock}>{children}</div>
                  <div className={styles.dividerBlock}>
                    <span />
                    <span className={styles.divider} />
                    <span />
                  </div>
                  <div className={styles.promoCode}>
                    <p>{t('home.orderFoodOnline.do you have a promo code?')}</p>
                    <p>
                      {t(
                        'home.orderFoodOnline.you can fill it out at the end of the order'
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className={cx(styles.selectColumn, styles.sideBarColumn)}>
                <div>
                  <h3>Objednávám jídlo pro</h3>
                </div>
                <div className={styles.sideBarBlock}>{sidebar}</div>
              </div> */}
              <div className={styles.selectColumn}>
                <span className={styles.sidebarBackground} />
                <div className={cx(styles.backBlock, styles.desktop)}>
                  {back}
                </div>
                <div>
                  <header>
                    <h3 className={cx('sectionTitleNew', styles.title)}>
                      {t('home.orderFoodOnline.step1.title')}
                    </h3>
                    <p>{t('home.orderFoodOnline.step1.subtitle')}</p>
                  </header>
                  {children}
                </div>
                <div className={styles.mobile}>
                  <div className={styles.priceBlock}>{price}</div>
                </div>
              </div>
              <div className={styles.resultColumn}>
                <div className={cx(styles.backBlock, styles.hidden)}>
                  {back}
                </div>
                <div>
                  <header style={{ paddingLeft: '32px', marginBottom: '32px' }}>
                    <h3 className={cx('sectionTitleNew', styles.title)}>
                      {t('forms.onlineOrderFormTitle4')}
                    </h3>
                  </header>
                </div>
                <div className={styles.desktop}>{sidebar}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.mobile}>
        <div className={styles.priceBlock}>{price}</div>
      </div>
    </section>
  )
}
