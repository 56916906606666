import React, { useEffect, useState, useCallback } from 'react'

import { useLocalStorage } from '../../../../../shared/lib/local-storage'
import { OrderFoodOnlineTemplate } from '../../components/templates/order-food-online-template'
import { OrderStorageType, useGetters, useSetters } from '../../model'
import { OrderFoodNumberOfPeople } from './order-food-number-of-people'
import { OrderFoodCustomerGender } from './order-food-customer-gender'
import { OrderFoodProgram } from './order-food-program'
import { OrderFoodNumberOfMeals } from './order-food-number-of-meals'
import { OrderFoodProgramDuration } from './order-food-program-duration'
import { OrderFoodWeekMealLength } from './order-food-week-meal-length'
import { OrderFoodOnlineSidebar } from './order-food-online-sidebar'
import { OrderFoodOnlinePrice } from './order-food-online-price'
import { BackButton } from '../../components/back-button'
import styles from '../../components/templates/order-food-online-template.module.css'
import { OrderFoodCustomerMenu } from './order-food-customer-menu'
import { useTranslation } from 'react-i18next'

const useStorage = () => {
  const [orderStorage] = useLocalStorage(OrderStorageType.orderFoodOnline, {})

  const setters = useSetters()

  useEffect(() => {
    if (orderStorage) {
      setters.setState(orderStorage)
    }
    localStorage.removeItem('formName')
  }, [])
}

export const OrderFoodOnline = () => {
  const [isChanged, setIsChanged] = useState(false)
  useStorage()
  const { t } = useTranslation()
  const getters = useGetters()
  const setters = useSetters()
  const [, setOrderStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )

  const getMenus = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_APP_API_URL}/v2/tag-replacements`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const responseResult = await response.json();
      setters.setOrderExeptions(responseResult)

    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  }, []);
  useEffect(() => {
    getMenus();
  }, [getMenus]);
  const isEn = document.location.pathname.includes("/en")

  const handleOrderOnline = event => {
    event.preventDefault()
    setOrderStorage(getters.state)
    window.location.href = isEn ? '/en/order-food-online/order-food-contact-information' : '/order-food-online/order-food-contact-information'
  }

  const handleBack = () => {
    setOrderStorage({})
  }

  useEffect(() => {
    setOrderStorage(getters.state)
  }, [])

  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState('');
  const webVersions = ['new-calc-A', 'new-calc-B']

  useEffect(() => {
    const curVersion = localStorage.getItem('website-version');
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0;
    const randomVersion = webVersions[randomIndex];
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem('website-version', randomVersion);
      setWebsiteVersion(randomVersion);
    } else {
      setWebsiteVersion(curVersion);
    }
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);

    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';

    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state
    };

    setTimeout(() => {
      window.dataLayer.push({
        event: 'step_1_programme_selection',
        pageURL: isEn ? '/en/thank-you' : '/thank-you',
        pageType: 'Purchase',
        userInfo,
      });

    }, 300);

    window.dataLayer &&
      window.dataLayer.push({
        pageURL: '/order-food-online',
        testovani: websiteVersion,
        event: "ga.pageview"
      });
  }, []);




  const radioButtonChange = () => {
    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);
    setIsChanged(true);
    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components.postcode
      ? data?.deliveryAddress?.components.postcode
      : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';

    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    };

    setTimeout(() => {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'step_2_programme_selection_interaction',
          pageURL: isEn ? '/en/thank-you' : '/thank-you',
          pageType: 'Purchase',
          userInfo,
        });
    }, 300);
  };
  useEffect(() => {
    if (isChanged) {
      radioButtonChange();
    }
  }, [isChanged]);

  return (
    <form onSubmit={handleOrderOnline}>
      <OrderFoodOnlineTemplate
        back={
          <BackButton to={isEn ? "/en/#calculator" : "/#calculator"} onClick={handleBack}>
            {t('home.orderFoodOnline.backBtn')}
          </BackButton>
        }
        sidebar={<OrderFoodOnlineSidebar />}
        price={<OrderFoodOnlinePrice />}
      >
        {/* <div className={styles.selectBlock}>
          <OrderFoodNumberOfPeople />
        </div> */}
        <div className={styles.selectBlock}>
          <OrderFoodCustomerGender setIsChanged={setIsChanged} />
        </div>
        <div className={styles.selectBlock}>
          <OrderFoodCustomerMenu setIsChanged={setIsChanged} />
        </div>
        <div className={styles.selectBlock}>
          <OrderFoodProgram setIsChanged={setIsChanged} />
        </div>
        <div className={styles.selectBlock}>
          <OrderFoodNumberOfMeals setIsChanged={setIsChanged} />
        </div>
        <div className={styles.selectBlock}>
          <OrderFoodProgramDuration setIsChanged={setIsChanged} />
        </div>
        <div className={styles.selectBlock}>
          <OrderFoodWeekMealLength setIsChanged={setIsChanged} />
        </div>
      </OrderFoodOnlineTemplate>
    </form>
  )
}
