import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'
import { useModalContext } from '../../../../../shared/lib/react-context-modal'

import { useGetters } from '../../model'
import { Button2 } from '../../../../../components/button2'
import { OrderFoodCallMeModal } from './order-food-call-me-modal'
import styles from './order-food-online-price.module.css'

export const OrderFoodOnlinePrice = () => {
  const { t } = useTranslation()
  const getters = useGetters()

  const { showModal } = useModalContext()

  const handleCallMe = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer && window.dataLayer.push({
      event: `form_view_callback`,
      pageURL: window.location.pathname
    });
    localStorage.setItem('formName', "online-order-food")
    showModal(OrderFoodCallMeModal)

  }

  return (
    <div className={styles.priceContent}>
      <div>
        <div className={styles.row}>
          <span className={styles.label}>
            {t('home.orderFoodOnline.pricePerDay')}:
          </span>
          <span className={styles.value}>
            {numberFormat(getters.getOrderFoodPrice)} {t('czhKrona')}
          </span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>
            <Trans i18nKey="home.orderFoodOnline.totalPrice2-1">
              Celkem <span>(vč. DPH)</span>:
            </Trans>
          </span>
          <span className={cx(styles.value, styles.green)}>
            {numberFormat(getters.getOrderFoodPriceDiscountByDays)}{' '}
            {t('czhKrona')}
          </span>
        </div>
      </div>
      <div className={styles.submit}>
        <div className={styles.buttonBlock}>
          <Button2
            variant="outline"
            color="secondary"
            fullWidth
            onClick={handleCallMe}
            size="sm"
          >
            {t('home.orderFoodOnline.callMe')}
          </Button2>
        </div>
        <div className={styles.buttonBlock}>
          <Button2 color="secondary" buttonType="submit" fullWidth size="sm">
            {t('home.orderFoodOnline.orderOnline')}
          </Button2>
        </div>
      </div>
    </div>
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
