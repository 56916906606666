import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { IconButton } from '../../../../../components/icon-button'
import { BezRibIcon, MeatIcon, VegeIcon } from '../../components/icons'
import { ButtonGroup } from '../../components/button-group'
import { useSetters, useGetters } from '../../model'
import styles from './order-food-customer-menu.module.css'

export const OrderFoodCustomerMenu = ({ setIsChanged }) => {
  const { t } = useTranslation();
  const setters = useSetters();
  const getters = useGetters();

  const handleMeatClick = () => {
    if (!getters.isOrderFoodCustomerMenuMeat) {
      setters.setOrderFoodCustomerMenuMeat();
      setIsChanged(true);
    }
    const menu_choice = 'meat'
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_menu_choice',
        pageURL: `${window.location.pathname}/menu_choise=${menu_choice}`,
        menu_choice
      })


  };

  const handleVegeClick = () => {
    if (!getters.isOrderFoodCustomerMenuVege) {
      setters.setOrderFoodCustomerMenuVege();
      setIsChanged(true);
    }

    const menu_choice = 'vege'
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_menu_choice',
        pageURL: `${window.location.pathname}/menu_choise=${menu_choice}`,
        menu_choice,
      })

  };

  const handleBezRibClick = () => {
    if (!getters.isOrderFoodCustomerMenuBezRib) {
      setters.setOrderFoodCustomerMenuBezRib();
      setIsChanged(true);
    }
    const menu_choice = 'bezryb'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_menu_choice',
        pageURL: `${window.location.pathname}/menu_choise=${menu_choice}`,
        menu_choice,
      })

  };

  return (
    <div>
      <h3>{t('home.orderFoodOnline.customerMenuTitle')}</h3>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <ButtonGroup>
            <div className={styles.buttonBlock}>
              <IconButton fullWidth onClick={handleMeatClick}>
                <ButtonMeat>
                  {t('home.orderFoodOnline.customerMenuMeat')}
                </ButtonMeat>
              </IconButton>
            </div>
            <div className={styles.buttonBlock}>
              <IconButton fullWidth onClick={handleVegeClick}>
                <ButtonVege>
                  {t('home.orderFoodOnline.customerMenuVege')}
                </ButtonVege>
              </IconButton>
            </div>
            <div className={styles.buttonBlock}>
              <IconButton fullWidth onClick={handleBezRibClick}>
                <ButtonBezRib>
                  {t('home.orderFoodOnline.customerMenuBezRib')}
                </ButtonBezRib>
              </IconButton>
            </div>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};


const ButtonMeat = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuMeat,
      })}
    >
      <MeatIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonVege = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuVege,
      })}
    >
      <VegeIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonBezRib = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuBezRib,
      })}
    >
      <BezRibIcon />
      <p>{children}</p>
    </div>
  )
}
