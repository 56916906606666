import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useSetters, useGetters } from '../../model'
import { IconButton } from '../../../../../components/icon-button'
import {
  CheckRadioOffIcon,
  CheckRadioWhiteOnIcon,
} from '../../components/icons'
import styles from './order-food-week-meal-length.module.css'

export const OrderFoodWeekMealLength = ({ setIsChanged }) => {
  const { t } = useTranslation();
  const getters = useGetters();
  const setters = useSetters();

  const handleOrderFoodWeekMeal5DaysLength = () => {
    if (!getters.isOrderFoodWeekMeal5DaysLength) {
      setters.setOrderFoodWeekMeal5DaysLength();
      setIsChanged(true);
    }

    const week_length = '5 dni'
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_week_length',
        pageURL: `${window.location.pathname}/week_length=${week_length}`,
        week_length
      })


  };

  const handleOrderFoodWeekMeal6DaysLength = () => {
    if (!getters.isOrderFoodWeekMeal6DaysLength) {
      setters.setOrderFoodWeekMeal6DaysLength();
      setIsChanged(true);
    }
    const week_length = '6 dni'
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_week_length',
        pageURL: `${window.location.pathname}/week_length=${week_length}`,
        week_length
      })

  };

  return (
    <div>
      <h3>{t('home.orderFoodOnline.lengthOfWeekTitle')}</h3>
      <span className={styles.subTitleBlock}>
        <p>{t('home.orderFoodOnline.lengthOfWeekSubTitle')}</p>
      </span>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonBlock}>
            <IconButton
              onClick={handleOrderFoodWeekMeal5DaysLength}
              fullWidth
            >
              <Button
                title={`${t('home.orderFoodOnline.5 days')}`}
                subTitle={`(${t('home.orderFoodOnline.mondayToFriday')})`}
                active={getters.isOrderFoodWeekMeal5DaysLength}
              />
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton
              onClick={handleOrderFoodWeekMeal6DaysLength}
              fullWidth
            >
              <Button
                title={`${t('home.orderFoodOnline.6 days')}`}
                subTitle={`(${t('home.orderFoodOnline.mondayToSaturday')})`}
                active={getters.isOrderFoodWeekMeal6DaysLength}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const Button = props => {
  const { title, subTitle, active } = props

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: active,
      })}
    >
      <span className={styles.titleBlock}>
        <CheckRadio active={active} />
        <span className={styles.title}>{title}</span>
      </span>
      <p>{subTitle}</p>
    </div>
  )
}

const CheckRadio = props => {
  const { active } = props
  return active ? (
    <CheckRadioWhiteOnIcon width={16} />
  ) : (
    <CheckRadioOffIcon width={16} />
  )
}
