import React from 'react'
import classNames from 'classnames'
import styles from './badge.module.css'

export const Badge = props => {
  const { variant = 'orange', size = 'md' } = props

  return (
    <span
      className={classNames(styles.badge, {
        [styles.orange]: variant === 'orange',
        [styles.darkOrange]: variant === 'darkOrange',
        [styles.yellow]: variant === 'yellow',
        [styles.sm]: size === 'sm',
        [styles.size]: size === 'md',
      })}
    >
      {props.children}
    </span>
  )
}
